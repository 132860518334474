import React, { useContext } from "react";

/// React router dom
import { Routes, Route, Outlet, } from "react-router-dom";

/// Css
import "./index.css";
import "./chart.css";
import "./step.css";

/// Layout
import Nav from "./layouts/nav";
import Footer from "./layouts/Footer";
//import Main from './layouts/Main';

import ScrollToTop from "./layouts/ScrollToTop";
/// Dashboard
import Home from "./components/Dashboard/Home";

import Error404 from "./pages/Error404";

import { ThemeContext } from "../context/ThemeContext";
import Partners from "./components/Pages/Partners";
import StakingBonus from "./components/Pages/StakingBonus";
import LevelBonus from "./components/Pages/LevelBonus";
import ReferralBonus from "./components/Pages/ReferralBonus";
import GrowthLevelBonus from "./components/Pages/GrowthLevelBonus";
import RewardIncome from "./components/Pages/RewardIncome";
import TreeStructure from "./components/Pages/TreeStructure";
import Form from "./components/Pages/Form";
import MyTeam from "./components/Pages/MyTeam";
import GrowthClaim from "./components/Pages/GrowthClaim";
import WithDrawToken from "./components/Pages/WithDrawToken";
import ClaimBonus from "./components/Pages/ClaimBonus";
import Reward from "./components/Pages/Reward";
import Coupon from "./components/Pages/Coupon";
import CouponHistory from "./components/Pages/CouponHistory";
import BuyBack from "./components/Pages/BuyBack";
import RoiBonus from "./components/Pages/RoiBonus";
import Transfer from "./components/Pages/Transfer";

const Markup = () => {
  const allroutes = [
    /// Dashboard
   
    // { url: "/kyc", component: <KycStatus/> },
    { url: "", component: <Home /> },
    { url: "/dashboard", component: <Home /> },
    { url: "/roiBonus", component: <RoiBonus /> },
    { url: "/Partner", component: <Partners /> },
    { url: "/get-Buyback", component: <BuyBack /> },
    { url: "/StakingBonus", component: <StakingBonus /> },
    { url: "/Level", component: <LevelBonus /> },
    { url: "/Referral", component: <ReferralBonus /> },
    { url: "/growthLevelBonus", component: <GrowthLevelBonus /> },
    { url: "/treeStructure", component: <TreeStructure /> },
    { url: "/reward&Recognition", component: <RewardIncome /> },
    { url: "/claimBonus", component: <ClaimBonus /> },
    { url: "/GrowthClaim", component: <GrowthClaim /> },
    { url: "/formSection", component: <Form /> },
    { url: "/myteam", component: <MyTeam /> },
    { url: "/withDrawToken", component: <WithDrawToken /> },
    { url: "/reward", component: <Reward /> },
    { url: "/coupon", component: <Coupon /> },
    { url: "/coupon_history", component: <CouponHistory /> },
    { url: "/transfer", component: <Transfer /> },
  ];

  return (
    <>
      <Routes>
        <Route path="page-error-404" element={<Error404 />} />

        <Route element={<MainLayout />}>
          {allroutes.map((data, i) => (
            <Route
              key={i}
              exact
              path={`${data.url}`}
              element={data.component}
            />
          ))}
        </Route>
      </Routes>
      <ScrollToTop />
    </>
  );
};

function MainLayout() {
  const { menuToggle } = useContext(ThemeContext);
  return (
    <div
      id="main-wrapper"
      className={`show ${menuToggle ? "menu-toggle" : ""}`}
    >
      <Nav />
      <div
        className="content-body"
        style={{ minHeight: window.screen.height - 45 }}
      >
        <div className="container-fluid">
          <Outlet />
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Markup;
