import React, { useEffect, useState } from "react";
import { Tab } from "react-bootstrap";
import moment from "moment/moment";
import { Loader } from "../Dashboard/Loader";
import { getTransferHistory, transferAmount } from "../../../helpers/api"; // Replace with your API helpers
import { useDispatch, useSelector } from "react-redux";
import { Pagination } from "@mui/material";

const Transfer = () => {
  const [transferData, setTransferData] = useState({
    userId: "",
    amount: "",
  });
  const [historyData, setHistoryData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [historyLoading, setHistoryLoading] = useState(false);
  const [apidata, setApidata] = useState([]);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const { walletAddress } = useSelector((action) => {
    return action.auth;
  });

  // Fetch transfer history
  // useEffect(() => {
  //   setHistoryLoading(true);
  //   getTransferHistory(walletAddress,1) // API call to fetch transfer history
  //     .then((res) => {
  //       setTotal(res?.record_count);
  //       setApidata(res?.data);
  //     })
  //     .catch(() => setHistoryLoading(false));
  // }, [walletAddress]);

  function getLevelData(p) {
    getTransferHistory(walletAddress, p).then((res) => {

      console.log(res, "res::cdjjdj")
      setTotal(res?.record_count);
      setApidata(res?.data);
      // setLoading(true);
    });
  }

  useEffect(() => {
    getLevelData(page);

  }, [walletAddress, page]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setTransferData({ ...transferData, [name]: value });
  };

  const handleTransfer = (e) => {
    e.preventDefault();
    console.log(" walla daddrr ", walletAddress)
    if (!transferData.userId || !transferData.amount || !walletAddress) {
      alert("Please fill in all fields.");
      return;
    }
    setLoading(true);
    transferAmount(walletAddress, transferData.userId, transferData.amount) // API call to transfer amount
      .then((res) => {
        alert(res.message);
        setTransferData({ userId: "", amount: "" }); // Clear form
        // Refresh history after transfer
        getLevelData(1);
      })
      .then((updatedHistory) => {
        setHistoryData(updatedHistory || []);
      })
      .finally(() => setLoading(false));
  };

  const handleChange = (e, p) => {
    getLevelData(p);
    //console.log(p, page, "page:::");
    setPage(p);
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div className="row">
          <div className="col-xxl-12">
            <div className="card">
              <Tab.Container defaultActiveKey="All">
                <div className="card-header border-0 pb-2 flex-wrap">
                  <h4 className="heading me-2">Transfer Staking Wallet</h4>
                </div>

                {/* Transfer Form */}
                <div className="card-body pt-2">
                  <form onSubmit={handleTransfer}>
                    <div className="mb-3">
                      <label className="form-label">Recipient User ID</label>
                      <input
                        type="text"
                        className="form-control"
                        name="userId"
                        value={transferData.userId}
                        onChange={handleInputChange}
                        placeholder="Enter User ID"
                      />
                    </div>
                    <div className="mb-3">
                      <label className="form-label">Amount</label>
                      <input
                        type="number"
                        className="form-control"
                        name="amount"
                        value={transferData.amount}
                        onChange={handleInputChange}
                        placeholder="Enter Amount"
                      />
                    </div>
                    <button type="submit" className="btn btn-primary">
                      Transfer
                    </button>
                  </form>
                </div>

                {/* Transfer History */}
                <div className="card-body pt-4">
                  <h4 className="heading">Transfer History</h4>
                  <div
                    id="history_wrapper"
                    className="table-responsive dataTablehistory"
                  >
                    <table
                      id="example"
                      className="table shadow-hover dataTable display"
                      style={{ minWidth: "845px" }}
                    >
                      <thead>
                        <tr>
                          <th className="text-white">Amount</th>
                          <th className="text-white"> UserId</th>
                          <th className="text-white">Type</th>
                          <th className="text-white">Date</th>
                        </tr>
                      </thead>
                      <tbody>
                        {apidata?.length > 0 ? (
                          apidata?.map((e, index) => (
                            <tr key={index}>
                              <td>$ {(e?.amount)?.toFixed(3)}</td>
                              <td>{e?.touserId}</td>
                              <td>{walletAddress === e?.user ? "Send" : "Received"}</td>
                              <td>
                                {moment(e.createdAt).format('MMMM Do YYYY')}
                                {/* {moment(e.createdAt).format('MMMM Do YYYY, h:mm:ss A')} */}
                              </td>


                              {/* <td>
                                                                    {moment(
                                                                        (e.createdAt)
                                                                    ).fromNow()}
                                                                </td> */}
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan={7}>
                              <div className="text-center  mt-4 mb-2 fw-bold fs-5 dash-heading">
                                No Data Found.
                              </div>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                    <div className="d-sm-flex text-center justify-content-between align-items-center mt-3 mb-3">
                      <div className="dataTables_info"></div>
                      <div
                        className="dataTables_paginate paging_simple_numbers mb-0"
                        id="application-tbl1_paginate"
                      >
                        <Pagination
                          color="primary"
                          count={Math.ceil(total / 10)}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </Tab.Container>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Transfer;
